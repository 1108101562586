<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-title>
              目前綁定任務
              <v-spacer></v-spacer>
              <v-btn
                v-if="canEdit"
                class="
                  font-weight-normal
                  text-capitalize
                  btn-primary
                  bg-gradient-secondary
                  py-3
                  px-6
                  ms-3
                "
                @click="onEditClick"
                :disabled="isLoading"
                :loading="isLoading"
              >
                綁定新任務
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> 任務ID： </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="mt-2 mb-0 text-lg font-weight-light text-wrap">
                      {{ currentCampaign.campaignId }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> 任務名稱： </v-list-item-title>
                  <v-list-item-subtitle>
                    <div
                      class="
                        mt-2
                        mb-0
                        text-lg
                        font-weight-light
                        text-wrap
                        line-height-md
                      "
                    >
                      {{ currentCampaign.campaignName }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> 任務描述： </v-list-item-title>
                  <v-list-item-subtitle>
                    <div
                      class="
                        mt-2
                        mb-0
                        text-lg
                        font-weight-light
                        text-wrap
                        line-height-md
                      "
                    >
                      {{ currentCampaign.campaignDesc }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> 任務期間： </v-list-item-title>
                  <v-list-item-subtitle>
                    <div
                      class="
                        mt-2
                        mb-0
                        text-lg
                        font-weight-light
                        text-wrap
                        line-height-md
                      "
                    >
                      {{
                        currentCampaign.startAt
                          ? moment(currentCampaign.startAt * 1000).format(
                              "YYYY/MM/DD HH:mm:ss"
                            )
                          : ""
                      }}
                      -
                      {{
                        currentCampaign.endAt
                          ? moment(currentCampaign.endAt * 1000).format(
                              "YYYY/MM/DD HH:mm:ss"
                            )
                          : ""
                      }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> 獎勵類型： </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="mt-2 mb-0 text-lg font-weight-light text-wrap">
                      <span v-if="currentCampaign.rewardType === 'points'">
                        點數
                      </span>
                      <span v-if="currentCampaign.rewardType === 'coupon'">
                        優惠券
                      </span>
                      <span v-if="currentCampaign.rewardType === 'misc'">
                        其它
                      </span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> 獎勵描述： </v-list-item-title>
                  <v-list-item-subtitle>
                    <div
                      class="
                        mt-2
                        mb-0
                        text-lg
                        font-weight-light
                        text-wrap
                        line-height-md
                      "
                    >
                      {{ currentCampaign.rewardDesc }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    成功捐贈一張發票可獲得獎勵數量 ：
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="mt-2 mb-0 text-lg font-weight-light text-wrap">
                      {{ currentCampaign.rewardUnitAmount }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="openEditDialog"
        persistent
        no-click-animation
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"> 綁定捐贈發票活動任務 </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="editItem.campaignId"
                      label="請選擇綁定任務"
                      :items="availableCampaigns"
                      item-text="campaignName"
                      item-value="campaignId"
                      :rules="rules.required"
                      :disabled="isProcessing"
                      :no-data-text="$t(`common['No Data']`)"
                      clearable
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.campaignName }} （
                        {{
                          data.item.startAt
                            ? moment(data.item.startAt * 1000).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )
                            : ""
                        }}
                        -
                        {{
                          data.item.endAt
                            ? moment(data.item.endAt * 1000).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )
                            : ""
                        }}
                        ）
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.campaignName }} （
                        {{
                          data.item.startAt
                            ? moment(data.item.startAt * 1000).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )
                            : ""
                        }}
                        -
                        {{
                          data.item.endAt
                            ? moment(data.item.endAt * 1000).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )
                            : ""
                        }}）
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          獎勵類型：
                          <p class="mt-2 mb-0 text-body font-weight-light">
                            <span
                              v-if="selectedCampaign.rewardType === 'points'"
                            >
                              點數
                            </span>
                            <span
                              v-if="selectedCampaign.rewardType === 'coupon'"
                            >
                              優惠券
                            </span>
                            <span v-if="selectedCampaign.rewardType === 'misc'">
                              其它
                            </span>
                          </p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          獎勵描述：
                          <p class="mt-2 mb-0 text-body font-weight-light">
                            {{ selectedCampaign.rewardName }}
                          </p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editItem.rewardUnitAmount"
                      type="number"
                      label="每成功捐贈一張發票可獲得獎勵數量"
                      :rules="rules.integer"
                      :disabled="isProcessing"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeEditDialog"
              :disabled="isProcessing"
            >
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onSaveEditItem"
              :disabled="isProcessing"
              :loading="isProcessing"
            >
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import {
  fetchDonateInvoiceCampaign,
  fetchDonateCandidateCampaigns,
} from "src/apis/fetchData";
import { updateDonateInvoiceCampaign } from "src/apis/updateData";
import { checkPermission } from "src/util/utils";

export default {
  name: "DonateInvoice",
  components: {},
  data() {
    return {
      moment: moment,
      isLoading: false,
      currentCampaign: {},
      openEditDialog: false,
      isProcessing: false,
      defaultEditItem: {},
      editItem: {},
      availableCampaigns: [],
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        integer: [
          (v) => !!v || this.$t(`common["Required"]`),
          (v) => v > 0 || this.$t(`validation["Must be positive integer"]`),
        ],
        maxTransfer(maxValue, v) {
          return (v || "") <= maxValue || `點數最多為${maxValue}`;
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    selectedCampaign() {
      return this.editItem.campaignId
        ? this.availableCampaigns.find(
            (i) => i.campaignId === this.editItem.campaignId
          )
        : {};
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:DonateManage",
          "EditDonateInvoices",
          true
        );
      }
      return false;
    },
  },
  watch: {},
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
  },
  methods: {
    init() {
      this.getDonateCampaign();
      this.getCandidateCampaigns();
    },
    getDonateCampaign() {
      fetchDonateInvoiceCampaign(this.merchantId)
        .then((res) => {
          if (res && res.data && res.data) {
            this.currentCampaign = { ...res.data };
          }
        })
        .catch((e) => {
          console.error("failed to get donata invoice campaign", e);
          this.currentCampaign = {};
        });
    },
    getCandidateCampaigns() {
      this.isLoading = true;
      fetchDonateCandidateCampaigns(this.merchantId)
        .then((res) => {
          if (res && res.data && res.data) {
            this.availableCampaigns = [...res.data];
          }
        })
        .catch((e) => {
          console.error("failed to get donata candidate campaigns", e);
          this.availableCampaigns = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onEditClick() {
      this.editItem = Object.assign({}, this.defaultEditItem);
      if (
        this.currentCampaign.endAt &&
        this.currentCampaign.endAt > moment().unix()
      ) {
        this.$swal({
          text: "目前綁定的任務尚未到結束時間，是否確定要綁定新的任務？",
          type: "info",
          showCancelButton: true,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          cancelButtonText: this.$i18n.t(`common["Cancel"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        }).then((res) => {
          if (res.isConfirmed) {
            this.openEditDialog = true;
          }
        });
      } else {
        this.openEditDialog = true;
      }
    },
    closeEditDialog() {
      this.$refs.editForm.reset();
      this.$refs.editForm.resetValidation();
      this.openEditDialog = false;
    },
    onSaveEditItem() {
      this.isProcessing = true;

      const valid = this.$refs.editForm.validate();
      if (valid) {
        // binding new campaign
        const campaign = Object.assign(
          {
            rewardUnitAmount: this.editItem.rewardUnitAmount,
            rewardDesc: this.selectedCampaign.rewardName,
          },
          this.selectedCampaign
        );
        updateDonateInvoiceCampaign(this.merchantId, campaign)
          .then((result) => {
            console.log("updateDonateInvoiceCampaign done:", result);
            this.getDonateCampaign();
            this.isProcessing = false;
            this.closeEditDialog();
          })
          .catch((e) => {
            console.error("failed to get update invoice campaign", e);
            this.showErrorAlert("綁定失敗，請稍後重試。");
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        this.isProcessing = false;
      }
    },
    showErrorAlert(message, inputTitle) {
      this.$swal({
        title: inputTitle,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
</style>
